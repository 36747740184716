import * as React from 'react';
import { Stack } from '@lcifr/components/stack';
import { PageContent } from '@lcifr/components/page-content';
import { Layout } from 'components/Layout';
import { usePageContext } from '@lcifr/pages';
import { Box } from '@lcifr/components/box';

export type PageProps = {
  pageData: any;
};

export default function Page({ pageData }: PageProps) {
  const context = usePageContext();
  const footer = (pageData as any)?.pageDocument?.page?.footer;
  const header = {
    ...(pageData as any)?.i18n?.header,
    ...(context as any)?.pageDocument?.page?.header,
  };
  const socialProfiles = (pageData as any)?.i18n?.socialProfiles;
  return (
    <Layout header={header} footer={footer} socialProfiles={socialProfiles}>
      <PageContent>
        {(content) => <Stack space={[null]}>{content}</Stack>}
      </PageContent>
    </Layout>
  );
}
